/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

import { getAppName } from 'helpers/appName';

export default function themePalette(theme, mode = 'light') {
    const validAppName = getAppName();

    return {
        mode: mode,
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: theme.colors?.[`${validAppName}Light`],
            main: theme.colors?.[`${validAppName}Main`],
            dark: theme.colors?.[`${validAppName}Dark`],
            200: theme.colors?.primary200,
            800: theme.colors?.primary800
        },
        secondary: {
            light: theme.colors?.secondaryLight,
            main: theme.colors?.secondaryMain,
            dark: theme.colors?.secondaryDark,
            200: theme.colors?.secondary200,
            800: theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            20: mode === 'dark' ? 'red' : '#ffff',
            50: theme.colors?.grey50,
            100: '#EEEEEE',
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100
        },
        background: {
            paper: mode === 'dark' ? theme.colors?.darkPaper : theme.paper,
            default: mode === 'dark' ? theme.colors?.darkBackground : theme.backgroundDefault
        },
        text: {
            primary: mode === 'dark' ? theme.colors?.grey200 : theme.darkTextPrimary,
            secondary: mode === 'dark' ? theme.colors?.grey500 : theme.darkTextSecondary
        }
    };
}
