import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'app';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import 'react-phone-number-input/style.css';

import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //

if (process.env.REACT_APP_GTM_ID) {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM_ID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.prepend(noscript);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
