import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Currency } from 'helpers/formatCurrency';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileIcon from '../../assets/images/icons/file-upload-line.png';
import { toast } from 'react-toastify';
import DashboardService from 'service/dashboard.service';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles(() => ({
    boxUploadFile: {
        border: '1px dashed #959595',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        position: 'relative',
        cursor: 'pointer'
    },
    labelImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    boxLabel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    image: {
        borderRadius: '50%',
        background: '#eeee',
        p: 2,
        overflow: 'hidden',
        width: '43px',
        height: '40px'
    }
}));

const FormVerificationIdentity = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const eligible = useSelector((state) => state.authReducer.eligible);

    const [value, setValue] = useState({ identity_number: '', identity_image: '', phone_number: '', selfie_image: '' });

    const [loadingButton, setLoadingButton] = useState(false);

    const [identityImage, setIdentityImage] = useState(null);
    const [selfieImage, setSelfieImage] = useState(null);

    const [identityImageFile, setIdentityImageFile] = useState(null);
    const [selfieImageFile, setSelfieImageFile] = useState(null);

    const uploadImage = (event, setImageCallback, setFileCallback) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                toast.error('File size exceeds 5 MB', { position: 'top-center', theme: 'colored' });
                return;
            }
            if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
                toast.error('Only .jpg, .jpeg, or .png formats are allowed', { position: 'top-center', theme: 'colored' });
                return;
            }

            setFileCallback(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageCallback(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleConfirm = async (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const formData = new FormData();
        formData.append('identity_number', value?.identity_number);
        formData.append('phone_number', value?.phone_number);
        formData.append('identity_image', identityImageFile);
        formData.append('selfie_image', selfieImageFile);
        try {
            const res = await DashboardService.identityVerifcation(formData);
            setLoadingButton(false);
            toast.success(res.data.message, { position: 'top-center', theme: 'colored' });
            dispatch({ type: 'OPEN_MODAL_REWARD', payload: { flag: 'dashboard', open: false } });
        } catch (error) {
            toast.error(error.response.data.message, { position: 'top-center', theme: 'colored' });
            setLoadingButton(false);
        }
    };

    const handleClose = () => {
        dispatch({ type: 'OPEN_MODAL_REWARD', payload: { flag: 'dashboard', open: false } });
    };

    return (
        <Box sx={{ width: '450px' }}>
            <Typography textAlign={'center'} fontSize={'24px'} fontFamily={'Manrope Semibold'} color={'#1D243C'}>
                Identity verification bonus
            </Typography>
            <Typography textAlign={'center'} color={'#959595'}>
                Verify your identity and phone number to get <Currency value={eligible?.identity?.reward?.amount} currency={'IDR'} /> bonus
            </Typography>

            <form onSubmit={handleConfirm}>
                <Box mt={5}>
                    <Typography fontSize={'14px'} color={'#1D243C'} fontWeight={'bold'} paddingBottom={0.5} htmlFor="regis">
                        Phone Number <span style={{ color: '#959595', fontWeight: '400' }}>(Phone number must be active)</span>
                    </Typography>
                    <TextField
                        id="phone_number"
                        value={value?.phone_number}
                        onChange={(e) => setValue({ ...value, phone_number: e.target.value })}
                        name="phone_number"
                        color={'primary'}
                        placeholder="Enter phone number"
                        fullWidth
                        required
                    />
                </Box>
                <Box mt={3}>
                    <Typography fontSize={'14px'} color={'#1D243C'} fontWeight={'bold'} paddingBottom={0.5} htmlFor="identity_number">
                        Identity Number (KTP)
                    </Typography>
                    <TextField
                        id="identity_number"
                        value={value?.identity_number}
                        onChange={(e) => setValue({ ...value, identity_number: e.target.value })}
                        name="identity_number"
                        color={'primary'}
                        placeholder="Enter identity number"
                        fullWidth
                        required
                    />
                </Box>
                <Box mt={3}>
                    <Typography fontSize={'14px'} color={'#1D243C'} fontWeight={'bold'} paddingBottom={0.5} htmlFor="regis">
                        Identity Image
                    </Typography>
                    <Box p={8} className={classes.boxUploadFile}>
                        <label htmlFor="picture__input" className={classes.labelImage}>
                            {identityImage ? (
                                <img
                                    src={identityImage}
                                    alt="Uploaded Preview"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '80px',
                                        width: 'auto',
                                        height: 'auto',
                                        objectFit: 'contain'
                                    }}
                                />
                            ) : (
                                <Box className={classes.boxLabel}>
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} className={classes.image}>
                                        <img src={fileIcon} alt="file icon" />
                                    </Box>

                                    <Box gap={1} display="flex" alignItems="center" justifyContent={'center'} mt={1}>
                                        <Typography color={'primary'}>Click to upload</Typography>
                                    </Box>

                                    <Typography fontSize={'12px'} color={'#959595'} mt={1}>
                                        (Maximum file size is 5 MB and in .jpg, .jpeg, or .png format)
                                    </Typography>
                                </Box>
                            )}
                        </label>

                        <input
                            type="file"
                            name="picture__input"
                            id="picture__input"
                            style={{ display: 'none' }}
                            onChange={(e) => uploadImage(e, setIdentityImage, setIdentityImageFile)}
                        />
                    </Box>
                </Box>
                <Box mt={3}>
                    <Typography fontSize={'14px'} color={'#1D243C'} fontWeight={'bold'} paddingBottom={0.5} htmlFor="regis">
                        Selfie Image
                    </Typography>

                    <Box p={8} className={classes.boxUploadFile}>
                        <label htmlFor="selfie_image_input" className={classes.labelImage}>
                            {selfieImage ? (
                                <img
                                    src={selfieImage}
                                    alt="Uploaded Preview"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '80px',
                                        width: 'auto',
                                        height: 'auto',
                                        objectFit: 'contain'
                                    }}
                                />
                            ) : (
                                <Box className={classes.boxLabel}>
                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} className={classes.image}>
                                        <img src={fileIcon} alt="file icon" />
                                    </Box>

                                    <Box gap={1} display="flex" alignItems="center" justifyContent={'center'} mt={1}>
                                        <Typography color={'primary'}>Click to upload</Typography>
                                    </Box>

                                    <Typography fontSize={'12px'} color={'#959595'} mt={1}>
                                        (Maximum file size is 5 MB and in .jpg, .jpeg, or .png format)
                                    </Typography>
                                </Box>
                            )}
                        </label>

                        <input
                            type="file"
                            name="selfie_image_input"
                            id="selfie_image_input"
                            style={{ display: 'none' }}
                            onChange={(e) => uploadImage(e, setSelfieImage, setSelfieImageFile)}
                        />
                    </Box>
                </Box>
                <Box mt={4} p={1} borderRadius={2} border="1px solid #f0f0f0" bgcolor="#f9f9f9">
                    <Box display="flex" alignItems="center" gap={1.5}>
                        <ErrorIcon color="danger" />
                        <Typography fontSize="12px" color="#333">
                            After being verified by the admin, your identity photo will be immediately deleted from our system.
                        </Typography>
                    </Box>
                </Box>
                <Box mt={2}>
                    <LoadingButton size="large" variant="contained" loading={loadingButton} loadingPosition="start" fullWidth type="submit">
                        Confirm
                    </LoadingButton>
                    <Box mt={1} />
                    <Button
                        onClick={handleClose}
                        sx={{ background: 'none', color: theme.palette.primary.main, border: '2px solid #eeee' }}
                        size="large"
                        variant="contained"
                        color="inherit"
                        fullWidth
                        type="button"
                    >
                        Cancel
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default FormVerificationIdentity;
