export const dynamicBoxShadow = '0px 4px 15px 0px #1D243C14';
export const mainBox = (theme) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#ffffff',
    boxShadow: theme.palette.mode === 'dark' ? 'none' : dynamicBoxShadow,
    borderRadius: '12px',
    padding: '20px',
    color: theme.palette.text.primary
});
export const mainBox2 = { background: '#ffff', boxShadow: dynamicBoxShadow, borderRadius: '12px', padding: '5px' };
export const mainBox3 = { background: '#ffff', borderRadius: '12px', padding: '5px', border: '1px solid #eee' };
export const customSelect = { borderRadius: '8px', padding: '5px 10px', border: 'none', background: '#F7F7F7' };
export const labelInput = { fontSize: '14px', fontFamily: 'Manrope Semibold', color: '#1D243C' };
export const customStylesTable = {
    headCells: {
        style: {
            background: '#FFFF',
            color: '#1D243C',
            border: 'none',
            fontWeight: 'bold',
            fontSize: '14px'
        }
    },
    rows: {
        style: {
            padding: '16px 0px'
        }
    }
};
